import { useTheme } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/appContext";
import { DASHBOARD_RINGS } from "../../mock-data/dashboardRingsData";
import { DonutData } from "../../types/type";
import { isContainsValue } from "../../utils/commonUtils";
import Box from "../common/Box";
import Divider from "../common/Divider";
import LegendWithColor from "../common/LegendWithColor";
import Loader from "../common/Loader";
import Typography from "../common/Typography";
import { DASHBOARD_AI_VIEW, DASHBOARD_DATA_VIEW } from "../constants/constants";
import ImpactedDataCard from "./ImpactedDonutCard";
import HeroStat from "./HeroStat";
import { ToggleButtonGroup } from "../common/ToggleButton";
import { dashboardStats } from "../../mock-data/dashboardStats";

const ImpactedDataStoresPanel = () => {
  const {
    impactedDataStores,
    isFetchingImpactedStoresData,
    aiSummary,
    getAISummary,
    getImpactedStoresData,
    dashboardViewLense,
  } = useContext(AppContext);
  const [donutData, setDonutData] = useState<Array<any>>([]);
  const [toggleView, setToggleView] = useState<any>("Overview");

  useEffect(() => {
    if (dashboardViewLense === DASHBOARD_DATA_VIEW) {
      getImpactedStoresData();
    } else if (dashboardViewLense === DASHBOARD_AI_VIEW) {
      getAISummary();
    }
  }, [dashboardViewLense]);

  useEffect(() => {
    let donutData;
    if (
      dashboardViewLense === DASHBOARD_DATA_VIEW &&
      Object.values(impactedDataStores).length > 0
    ) {
      donutData = [
        {
          cardTitle: "Risk",
          donutData: [
            {
              value: impactedDataStores?.datastores_with_critical_risk || 0,
              color: theme.palette.critical.main,
              label: "Critical",
            },
            {
              value: impactedDataStores?.datastores_with_high_risk || 0,
              color: theme.palette.high.main,
              label: "High",
            },
          ],
          totalValue: impactedDataStores?.datastores || 0,
          defaultColor: theme.palette.surface20.main,
          variant: "AutoColor",
          innerRadius: 115,
          outerRadius: 130,
          boxSize: 300,
          donutHeight: 90,
          donutWidth: 90,
        },
        {
          cardTitle: "Policy Violations",
          donutData: [
            {
              value: impactedDataStores?.datastores_violating_policy || 0,
              color: theme.palette.critical.main,
              label: "Critical",
            },
            {
              value: 0,
              color: theme.palette.high.main,
              label: "High",
            },
          ],
          totalValue: impactedDataStores?.datastores || 0,
          defaultColor: theme.palette.surface20.main,
          variant: "AutoColor",
          innerRadius: 115,
          outerRadius: 130,
          boxSize: 300,
          donutHeight: 90,
          donutWidth: 90,
        },
        {
          cardTitle: "Sensitive Data",
          donutData: [
            {
              value: impactedDataStores?.datastores_with_sensitive_data || 0,
              color: theme.palette.critical.main,
              label: "Critical",
            },
            {
              value: 0,
              color: theme.palette.high.main,
              label: "High",
            },
          ],
          totalValue: impactedDataStores?.datastores || 0,
          defaultColor: theme.palette.surface20.main,
          variant: "AutoColor",
          innerRadius: 115,
          outerRadius: 130,
          boxSize: 300,
          donutHeight: 90,
          donutWidth: 90,
        },
        {
          cardTitle: "Shadow",
          donutData: [
            {
              value: impactedDataStores?.shadow_datastores + 0,
              color: theme.palette.lightMagenta.main,
              label: "Shadow Data",
              data: [
                {
                  value: impactedDataStores?.shadow_datastores || 0,
                  color: theme.palette.critical.main,
                  label: "Critical",
                },
                {
                  value: 0,
                  color: theme.palette.high.main,
                  label: "High",
                },
              ],
            },
            {
              value: impactedDataStores?.shadow_ai_datastores + 0,
              color: theme.palette.paleBlue.main,
              label: "Shadow AI/LLM",
              data: [
                {
                  value: impactedDataStores?.shadow_ai_datastores || 0,
                  color: theme.palette.critical.main,
                  label: "Critical",
                },
                {
                  value: 0,
                  color: theme.palette.high.main,
                  label: "High",
                },
              ],
            },
          ],
          totalValue: impactedDataStores?.datastores || 0,
          defaultColor: theme.palette.surface20.main,
          variant: "AutoColor",
          innerRadius: 115,
          outerRadius: 130,
          boxSize: 300,
          donutHeight: 90,
          donutWidth: 90,
        },
      ];
    } else if (dashboardViewLense === DASHBOARD_AI_VIEW) {
      donutData = [
        {
          cardTitle: "Documents",
          className: "row-1",
          donutData: [
            {
              value: DASHBOARD_RINGS.documentWithFindgins || 0,
              color: "#462AF8",
              label: "With Findings",
            },
            {
              value: DASHBOARD_RINGS.documentWithoutFindings || 0,
              color: "#C7BFFC",
              label: "Without Findings ",
            },
          ],
          totalValue: dashboardStats.all_documents,
          value: dashboardStats.documents_with_findings,
          suffix: " with Findings",
          defaultColor: theme.palette.surface20.main,
          variant: "AutoColor",
          innerRadius: 115,
          outerRadius: 130,
          boxSize: 300,
          donutHeight: 90,
          donutWidth: 90,
        },
        {
          cardTitle: "Applications",
          className: "row-2",
          donutData: [
            {
              value: dashboardStats.applications || 0,
              color: "#D1AF7C",
              label: "Langchain",
            },
          ],
          totalValue: 4,
          value: DASHBOARD_RINGS.applications,
          defaultColor: theme.palette.surface20.main,
          variant: "AutoColor",
          innerRadius: 115,
          outerRadius: 130,
          boxSize: 300,
          donutHeight: 90,
          donutWidth: 90,
        },
        {
          cardTitle: "Users",
          className: "row-3",
          donutData: [
            {
              value: DASHBOARD_RINGS.usersWithIssues || 0,
              color: "#005BAF",
              label: "With Issues",
            },
            {
              value: DASHBOARD_RINGS.usersWithoutIssues || 0,
              color: "#92CAFF",
              label: "Without Issues",
            },
          ],
          value: dashboardStats.anomalous_users,
          totalValue: dashboardStats.total_users,
          suffix: "Anamolous",
          defaultColor: theme.palette.surface20.main,
          variant: "AutoColor",
          innerRadius: 115,
          outerRadius: 130,
          boxSize: 300,
          donutHeight: 90,
          donutWidth: 90,
        },

        //{
        //  cardTitle: "Policy Violations",
        //  donutData: [
        //    {
        //      value: DASHBOARD_RINGS.policyViolationsLLM || 0,
        //      color: "#AC6AEE",
        //      label: "LLM",
        //    },
        //    {
        //      value: DASHBOARD_RINGS?.policyViolationsApplications || 0,
        //      color: "#C7BFFC",
        //      label: "Applications",
        //    },
        //  ],
        //  totalValue: 4,
        //  defaultColor: theme.palette.surface20.main,
        //  variant: "AutoColor",
        //  innerRadius: 115,
        //  outerRadius: 130,
        //  boxSize: 300,
        //  donutHeight: 90,
        //  donutWidth: 90,
        //},
      ];
    }
    setDonutData(donutData || []);
  }, [impactedDataStores, aiSummary]);

  const theme = useTheme();
  const styles = {
    donutCardContainer: {
      position: "relative",
      backgroundColor: theme.palette.surface10.main,
      borderRadius: theme.spacing(0.5),
      width: "100%",
      display: "flex",
    },
    allDataImpactedText: {
      color: theme.palette.surface80.main,
      fontWeight: theme.typography.fontWeightMedium,
      maxWidth: "5rem",
    },
    sensitiveDataText: {
      color: theme.palette.surface70.main,
      fontWeight: theme.typography.fontWeightRegular,
      maxWidth: "9.375rem",
    },
    highlightDataText: {
      color: theme.palette.surface80.main,
      fontWeight: theme.typography.fontWeightMedium,
    },
    legendContainer: {
      position: "absolute",
      bottom: 0,
      right: 0,
      display: "flex",
      gap: theme.spacing(2),
      paddingX: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      alignItems: "center",
      zIndex: 20,
    },
    dividerStyles: {
      height: "13px",
      alignSelf: "center",
      backgroundColor: theme.palette.surface40.main,
      marginY: 0,
    },
    legendGroup: {
      display: "flex",
      gap: theme.spacing(3),
    },
    totalDataStore: {
      opacity: 0.5,
    },
    flex: {
      width: "100%",
      //display: "flex",
      //justifyContent: "space-between",
      display: "grid",
      gridTemplateColumns: "repeat(4, 1fr)",
    },
    toggle: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1.25),
      borderLeft: `1px solid ${theme.palette.surface20.main}`,
      padding: theme.spacing(2),
      justifyContent: "center",
    },
    toggleButton: {
      background: "#141728",
    },
  };

  const donutLegends = [
    {
      label: "Critical",
      color: theme.palette.critical.main,
    },
    {
      label: "High",
      color: theme.palette.high.main,
    },
    {
      label: "Shadow Data",
      color: theme.palette.lightMagenta.main,
    },
    {
      label: "Shadow AI/LLM",
      color: theme.palette.lightBlue.main,
    },
  ];

  const toggleButtons = [
    {
      id: "overview",
      label: "Overview",
    },
    {
      id: "policy_violations",
      label: "Policy Violations",
    },
  ];

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newValue: string | null
  ) => {
    if (newValue !== null) {
      setToggleView(newValue);
    }
  };

  return (
    <Box sx={styles.donutCardContainer}>
      {isFetchingImpactedStoresData ? (
        <Loader height="100px" />
      ) : (
        <Box sx={styles.flex}>
          {donutData.map(
            (
              {
                cardTitle,
                donutData,
                totalValue,
                defaultColor,
                variant,
                innerRadius,
                outerRadius,
                boxSize,
                donutHeight,
                donutWidth,
                value,
                suffix,
                className,
              },
              index
            ) => (
              <HeroStat
                label={cardTitle}
                key={index}
                value={value}
                suffix={suffix}
                outOf={totalValue}
                classList={className}
              />
            )
          )}
          <Box sx={styles.toggle}>
            <Typography variant="body2" color={theme.palette.surface60.main}>
              Show Data As
            </Typography>{" "}
            <ToggleButtonGroup
              exclusive
              options={toggleButtons}
              value={toggleView}
              onChange={handleChange}
              sx={styles.toggleButton}
              defaultValue={toggleView}
            />{" "}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ImpactedDataStoresPanel;
