import { useTheme } from "@mui/material";
import Box from "../../components/common/Box";
import Typography from "../../components/common/Typography";
import { SyntheticEvent, useEffect, useMemo, useState } from "react";
import { Tab, TabPanel, Tabs } from "../../components/common/Tabs";
import Divider from "../../components/common/Divider";
import TextButton from "../../components/common/TextButton";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "../../components/common/Table";
import { policyTableData, topicsTableData, entityTableData } from "./mockData";
import Button from "../../components/common/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import ConstructionIcon from "@mui/icons-material/Construction";
import { AddTopic } from "./AddTopic";
import { AddEntity } from "./AddEntity";
import { AddPolicyPage } from "./AddPolicyPage";
import moment from "moment";
import { Menu, MenuItem } from "../../components/common/Menu";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import HexagonOutlinedIcon from "@mui/icons-material/HexagonOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import { capitalizeFirstLetter } from "../../utils/commonUtils";
import AddClassificationLabels from "./AddClassificationLabels";
import { classificationLabelsList } from "../../mock-data/classificationLabelsData";

const TabContent = ({ count, label }) => {
  const theme = useTheme();
  const styles = {
    tab: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
    },
    count: {
      fontFamily: "Manrope",
      fontSize: "32px",
    },
  };
  return (
    <Box sx={styles.tab}>
      <Typography sx={styles.count} color={theme.palette.surface80.main}>
        {count}
      </Typography>
      <Typography variant="caption" color={theme.palette.surface80.main}>
        {label}
      </Typography>
    </Box>
  );
};

type TableAlign =
  | "left"
  | "center"
  | "right"
  | "justify"
  | "inherit"
  | undefined;

export const PolicyPage = () => {
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const open = Boolean(menuAnchor);
  const [tabValue, setTabValue] = useState("0");
  const [policyTabValue, setPolicyTabValue] = useState("");
  const [isAddPolicy, setIsAddPolicy] = useState(false);
  const [isOpenTopic, setIsOpenTopic] = useState(false);
  const [isOpenEntity, setIsOpenEntity] = useState(false);
  const [policyData, setPolicyData] = useState<any>([]);
  const [topicData, setTopicData] = useState<any>([]);
  const [entityData, setEntityData] = useState<any>([]);
  const [labelsData, setLabelsData] = useState<any>([]);
  const [openClassificationLabel, setOpenClassificationLabel] = useState(false);

  const theme = useTheme();
  const styles = {
    main: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(4),
    },
    name: {
      fontSize: "13px",
      lineheight: "24px",
    },
    lastOccur: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1),
    },
    tableContainer: {
      maxHeight: "100%",
      minHeight: "340px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      backgroundColor: theme.palette.surface10.main,
      paddingY: theme.spacing(1.5),
    },
    tableHead: {
      "&.MuiTableCell-head": {
        paddingY: theme.spacing(1.25),
        zIndex: 0,
      },
    },
    tableCell: {
      fontSize: "13px",
      padding: theme.spacing(2),
      "&.MuiTableCell-root ": {
        borderBottom: `1px solid ${theme.palette.surface20.main}`,
      },
      verticalAlign: "middle",
    },
    tableCellLast: {
      display: "flex",
      alignItems: "center",
      justifyContent: "end",
      gap: theme.spacing(1.5),
    },
    tableCellAction: {
      display: "flex",
      alignItems: "center",
      fontSize: "13px",
      color: theme.palette.primaryBlue.main,
      cursor: "pointer",
    },
    tableActionDivider: {
      height: "18px",
      margin: theme.spacing(0.26, 0),
      width: "1px",
      background: theme.palette.surface20.main,
    },
    deleteIcon: {
      color: theme.palette.primaryBlue.main,
      width: "18px",
      cursor: "pointer",
      ":hover": {
        color: theme.palette.secondaryBlue.main,
      },
    },
    mainContent: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(3.5),
    },
    tabPanel: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    betaTag: {
      backgroundColor: theme.palette.surface20.main,
      paddingX: theme.spacing(0.5),
      marginLeft: theme.spacing(0.5),
      borderRadius: theme.spacing(0.5),
      fontSize: "10px",
      color: theme.palette.surface80.main,
    },
    menuItem: {
      display: "flex",
      gap: theme.spacing(2),
    },
    menuText: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
    },
    flex: { display: "flex", alignItems: "center", gap: theme.spacing(1) },
    flexCol: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
    },
  };

  const handleTabChange = (event: Event | SyntheticEvent, newValue: string) =>
    setTabValue(newValue.toString());

  const handleAddDialog = (event, newValue) => {
    switch (Number(newValue)) {
      case 0:
        setMenuAnchor(event?.target);
        break;
      case 1:
        setIsOpenTopic(true);
        break;
      case 2:
        setIsOpenEntity(true);
        break;
      case 3:
        setOpenClassificationLabel(true);
        break;
      default:
        break;
    }
  };

  const policyTabs = useMemo(() => {
    let violations = 0,
      policies = 0,
      entities = 0,
      topics = 0;

    policyData?.forEach((policy) => {
      violations += policy?.violations || 0;
      policies += 1;
      entities +=
        policy?.config?.SemanticGuardrailConfig?.entities?.length || 0;
      topics += policy?.config?.SemanticGuardrailConfig?.topics?.length || 0;
    });

    return [
      {
        label: "Violations",
        count: violations,
      },
      {
        isDivider: true,
      },
      {
        label: "Total Policies",
        count: policies,
      },
      {
        label: "Entities Blocked",
        count: entities,
      },
      {
        label: "Topics Blocked",
        count: topics,
      },
    ];
  }, [policyData]);

  const appendZero = (value) => {
    if (value < 10) {
      return `0${value}`;
    }
    return value;
  };

  const policyTableCols = [
    {
      title: "Policy Name",
      render: (row: any) => (
        <Typography sx={styles.name}>{row?.policyName}</Typography>
      ),
    },
    {
      title: "Type",
      field: "type",
      align: "",
      render: (row: any) => (
        <Typography sx={styles.name}>
          {capitalizeFirstLetter(row?.type)}
        </Typography>
      ),
    },
    {
      title: "Configuration",
      field: "config",
      align: "",
      render: (row: any) => {
        return (
          <Box sx={styles.flexCol}>
            <Box sx={styles.flex}>
              <Typography variant="body2" color={theme.palette.surface80.main}>
                Semantic Guardrail
              </Typography>
              <Typography
                variant="caption"
                color={theme.palette.surface50.main}
              >
                | {row?.config?.SemanticGuardrailConfig?.entities?.length || 0}{" "}
                Entities,{" "}
                {row?.config?.SemanticGuardrailConfig?.topics?.length || 0}{" "}
                Topics
              </Typography>
            </Box>
            <Box sx={styles.flex}>
              <Typography variant="body2" color={theme.palette.surface80.main}>
                Exceptions
              </Typography>
              <Typography
                variant="caption"
                color={theme.palette.surface50.main}
              >
                | {row?.config?.Exceptions ? "Allowed" : "Not Allowed"}{" "}
              </Typography>
            </Box>
            <Box sx={styles.flex}>
              <Typography variant="body2" color={theme.palette.surface80.main}>
                Confidentiality Tags
              </Typography>
              <Typography
                variant="caption"
                color={theme.palette.surface50.main}
              >
                | {row?.config?.ConfidentialityTags || "-"}
              </Typography>
            </Box>
          </Box>
        );
      },
    },
    {
      title: "Owner",
      field: "owner",
      align: "",
      render: (row: any) => (
        <Typography sx={styles.name}>{row?.owner}</Typography>
      ),
    },
    {
      title: "Added On",
      field: "addedOn",
      render: (row) => {
        const dateString = moment(row?.addedOn).format("yyyy-MM-DD");
        const hoursAndMinutes = moment(row?.addedOn).format("HH:mm:ss");

        return (
          <Typography sx={styles.name}>
            {`${dateString} | ${hoursAndMinutes}`}
          </Typography>
        );
      },
    },
    {
      title: "Violations",
      field: "violations",
      render: (row: any) => {
        return <Typography sx={styles.name}>{row?.violations}</Typography>;
      },
    },
  ];

  const topicsCols = [
    {
      title: "Topic",
      render: (row: any) => (
        <Typography sx={styles.name}>{row?.topic}</Typography>
      ),
    },
    {
      title: "Description",
      field: "policyType",
      align: "",
      render: (row: any) => (
        <Typography sx={styles.name}>{row?.description}</Typography>
      ),
    },
    {
      title: "Added On",
      field: "createdOn",
      align: "",
      render: (row: any) => {
        const dateString = moment(row?.createdOn).format("yyyy-MM-DD");
        const hoursAndMinutes = moment(row?.createdOn).format("HH:mm:ss");

        return (
          <Typography sx={styles.name}>
            {" "}
            {`${dateString} | ${hoursAndMinutes}`}
          </Typography>
        );
      },
    },
    {
      title: "",
      field: "actions",
      align: "center",
      render: (row: any) => {
        return (
          <Box sx={styles.tableCellLast}>
            <Box>
              <TextButton
                startIcon={<ConstructionIcon />}
                sx={styles.tableCellAction}
              >
                Modify Topics
              </TextButton>
            </Box>

            <Divider
              orientation="vertical"
              flexItem
              sx={styles.tableActionDivider}
            />
            <DeleteIcon sx={styles.deleteIcon} />
          </Box>
        );
      },
    },
  ];

  const entityCols = [
    {
      title: "Entities",
      render: (row: any) => (
        <Typography sx={styles.name}>
          {row?.entity || row?.customEntity[0]}
        </Typography>
      ),
    },
    {
      title: "Added On",
      field: "createdOn",
      align: "",
      render: (row: any) => {
        const dateString = moment(row?.createdOn).format("yyyy-MM-DD");
        const hoursAndMinutes = moment(row?.createdOn).format("HH:mm:ss");

        return (
          <Typography sx={styles.name}>
            {`${dateString} | ${hoursAndMinutes}`}
          </Typography>
        );
      },
    },
    {
      title: "",
      field: "actions",
      align: "center",
      render: (row: any) => {
        return (
          <Box sx={styles.tableCellLast}>
            <Box>
              <TextButton
                startIcon={<ConstructionIcon />}
                sx={styles.tableCellAction}
              >
                Modify Entity
              </TextButton>
            </Box>

            <Divider
              orientation="vertical"
              flexItem
              sx={styles.tableActionDivider}
            />
            <DeleteIcon sx={styles.deleteIcon} />
          </Box>
        );
      },
    },
  ];

  const classificationLabelsCol = [
    {
      title: "Label Name",
      field: "name",
      align: "",
      render: (row: any) => (
        <Typography sx={styles.name}>{row?.name}</Typography>
      ),
    },
    {
      title: "Priority",
      field: "priority",
      render: (row: any, index) => (
        <Typography sx={styles.name}>{index + 1}</Typography>
      ),
    },
    {
      title: "Added On",
      field: "addedOn",
      render: (row: any) => (
        <Typography sx={styles.name}>{row?.addedOn}</Typography>
      ),
    },
    {
      title: "Documents Tagged",
      field: "docsTagged",
      render: (row: any) => (
        <Typography sx={styles.name}>{row?.docsTagged}</Typography>
      ),
    },
  ];

  const tabData = [
    {
      id: "0",
      value: "policy",
      label: "Policy",
      tableCol: policyTableCols,
      tableValue: policyData,
      isTable: true,
    },
    {
      id: "1",
      value: "topics",
      label: "Topic",
      tableCol: topicsCols,
      tableValue: topicData,
      isTable: true,
    },
    {
      id: "2",
      value: "entities",
      label: "Entity",
      tableCol: entityCols,
      tableValue: entityData,
      isTable: true,
    },
    {
      id: "3",
      value: "classification-labels",
      label: "Classification Labels",
      tableCol: classificationLabelsCol,
      tableValue: labelsData,
      isTable: false,
    },
  ];

  useEffect(() => {
    setPolicyData(policyTableData);
    setTopicData(topicsTableData);
    setEntityData(entityTableData);
    setLabelsData(classificationLabelsList);
  }, []);

  useEffect(() => {
    const policyData: any = localStorage.getItem("policyConfig");
    const parsedData = JSON.parse(policyData);
    if (parsedData?.length > 0) {
      setPolicyData([...policyTableData, ...parsedData]);
    }
  }, [localStorage.getItem("policyConfig")]);

  useEffect(() => {
    const topicData: any = localStorage.getItem("topicsTableData");
    const parsedData = JSON.parse(topicData);
    if (parsedData?.length > 0) {
      setTopicData([...topicsTableData, ...parsedData]);
    }
  }, [localStorage.getItem("topicsTableData")]);

  useEffect(() => {
    const entityData: any = localStorage.getItem("entityTableData");
    const parsedData = JSON.parse(entityData);
    if (parsedData?.length > 0) {
      setEntityData([...entityTableData, ...parsedData]);
    }
  }, [localStorage.getItem("entityTableData")]);

  useEffect(() => {
    const labelsData: any = localStorage.getItem("classificationLabelsData");
    const parsedData = JSON.parse(labelsData);
    if (parsedData?.length > 0) {
      setLabelsData(parsedData);
    }
  }, [localStorage.getItem("classificationLabelsData")]);

  const getButtonText = () => {
    switch (Number(tabValue)) {
      case 0:
        return "Policy";
      case 1:
        return "Topic";
      case 2:
        return "Entity";
      case 3:
        return "Labels";
      default:
        return "Policy";
    }
  };

  const policyMenu = [
    {
      id: "identity",
      label: "Identity",
      subText: "Choose user groups and set specific access or mapping policies",
      icon: <PermIdentityOutlinedIcon />,
    },
    {
      id: "application",
      label: "Application",
      subText: "Set policies for specific applications",
      icon: <HexagonOutlinedIcon />,
      disabled: true,
    },
    {
      id: "cost",
      label: "Cost",
      subText: "Set policies to control usage for users and applications",
      icon: <MonetizationOnOutlinedIcon />,
      disabled: true,
    },
  ];

  return (
    <>
      <Box sx={styles.main}>
        <Typography color={theme.palette.surface100.main} variant="body2">
          Policy Settings
        </Typography>
        <Box sx={styles.mainContent}>
          <Box sx={styles.tabPanel}>
            <Tabs value={Number(tabValue)} onChange={handleTabChange}>
              {tabData?.map((item) => (
                <Tab
                  label={item?.label}
                  icon={
                    <>
                      {item?.label === "Topic" && (
                        <Box sx={styles.betaTag}>BETA</Box>
                      )}
                    </>
                  }
                  iconPosition="end"
                />
              ))}
            </Tabs>
            <Button
              variant="contained"
              onClick={(event) => handleAddDialog(event, tabValue)}
            >
              Add {getButtonText()}
            </Button>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: theme.spacing(1),
            }}
          >
            {tabValue === "0" ? (
              <Box
                sx={{
                  display: "flex",
                  gap: theme.spacing(4),
                  padding: theme.spacing(2),
                  backgroundColor: theme.palette.surface5.main,
                }}
              >
                {policyTabs.map((policy) => {
                  if (policy?.isDivider) {
                    return <Divider orientation="vertical" flexItem />;
                  }
                  return (
                    <TabContent count={policy?.count} label={policy?.label} />
                  );
                })}
              </Box>
            ) : null}
            {tabData?.map((item: any) => (
              <TabPanel value={tabValue} index={item?.id}>
                <TableContainer sx={styles.tableContainer}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        {item?.tableCol.map((col, index) => (
                          <TableCell
                            key={index}
                            sx={styles.tableHead}
                            align={
                              col?.align ? (col.align as TableAlign) : "left"
                            }
                          >
                            {col.title}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {item?.tableValue?.map((row: any, id: number) => (
                        <TableRow key={id}>
                          {item?.tableCol.map((col, index) => {
                            return (
                              <TableCell
                                key={index}
                                component="td"
                                scope="row"
                                sx={styles.tableCell}
                                align={
                                  col?.align
                                    ? (col.align as
                                        | "left"
                                        | "center"
                                        | "right"
                                        | "justify"
                                        | "inherit"
                                        | undefined)
                                    : "left"
                                }
                              >
                                {col?.render ? (
                                  col.render(row, id)
                                ) : (
                                  <Typography variant="caption">
                                    {row[col?.field || 0]}
                                  </Typography>
                                )}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </TabPanel>
            ))}
          </Box>
        </Box>
      </Box>
      <Menu
        open={open}
        anchorEl={menuAnchor}
        onClose={() => setMenuAnchor(null)}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {policyMenu.map((item) => (
          <MenuItem
            onClick={() => {
              setPolicyTabValue(item?.id);
              setIsAddPolicy(true);
              setMenuAnchor(null);
            }}
            disabled={item?.disabled}
          >
            <Box sx={styles.menuItem}>
              {item?.icon}
              <Box sx={styles.menuText}>
                <Typography
                  variant="subtitle1"
                  color={theme.palette.surface70.main}
                >
                  {item?.label}
                </Typography>
                <Typography
                  variant="body2"
                  color={theme.palette.surface50.main}
                  sx={{
                    breakWord: "break-word",
                  }}
                >
                  {item?.subText}
                </Typography>
              </Box>
            </Box>
          </MenuItem>
        ))}
      </Menu>
      <AddPolicyPage
        isOpen={isAddPolicy}
        handleClose={() => setIsAddPolicy(false)}
        labelsList={labelsData}
      />
      <AddTopic
        isOpen={isOpenTopic}
        handleClose={() => setIsOpenTopic(false)}
      />
      <AddEntity
        isOpen={isOpenEntity}
        handleClose={() => setIsOpenEntity(false)}
      />
      <AddClassificationLabels
        isOpen={openClassificationLabel}
        handleClose={() => setOpenClassificationLabel(false)}
        labelList={labelsData}
      />
    </>
  );
};
