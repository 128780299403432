import { useTheme } from "@mui/material";
import Box from "../../components/common/Box";
import Typography from "../../components/common/Typography";
import Chip from "../../components/common/Chip";
import LangchainIcon from "../../assets/img/langchainApp.svg";
import { getFileName } from "../../utils/commonUtils";
import Tooltip from "../../components/common/Tooltip";

const PromptPolicyDetails = ({ appDetails }) => {
  const theme = useTheme();
  const styles = {
    card: {
      backgroundColor: theme.palette.surface5.main,
      padding: theme.spacing(2),
      height: "100%",
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(3),
    },
    font13: {
      fontSize: "13px",
    },
    keyValue: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1.5),
    },
    policyChip: {
      display: "flex",
      width: "min-content",
      backgroundColor: "rgba(255, 101, 91, 0.20)",
      borderRadius: theme.spacing(0.5),
    },
    app: {
      display: "flex",
      gap: theme.spacing(1),
      alignItems: "center",
    },
    doc: {
      gap: theme.spacing(0.25),
    },
  };
  return (
    <Box sx={styles.card}>
      <Box sx={styles.keyValue}>
        <Typography sx={styles.font13} color={theme.palette.surface60.main}>
          Policies Violated
        </Typography>
        <Chip
          sx={styles.policyChip}
          label={
            <Typography variant="caption" color={theme.palette.surface70.main}>
              Sensitive identifiers in prompt session
            </Typography>
          }
        />
      </Box>
      <Box sx={styles.keyValue}>
        <Typography sx={styles.font13} color={theme.palette.surface60.main}>
          Application
        </Typography>
        <Box sx={styles.app}>
          <img src={LangchainIcon} alt="app" height={20} />
          <Typography variant="caption" color={theme.palette.surface70.main}>
            {appDetails?.app}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ ...styles.keyValue, ...styles.doc }}>
        <Typography sx={styles.font13} color={theme.palette.surface60.main}>
          Document
        </Typography>
        <Tooltip
          title={appDetails?.sourceFile}
          children={
            <span>
              <Typography
                variant="caption"
                color={theme.palette.surface70.main}
              >
                {getFileName(appDetails?.sourceFile)}
              </Typography>
            </span>
          }
        />
      </Box>
    </Box>
  );
};

export default PromptPolicyDetails;
