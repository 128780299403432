import { memo, useCallback, useEffect, useRef, useState } from "react";
import Box from "./Box";
import * as d3 from "d3";
import { useTheme } from "@mui/material";
import ReactDOMServer from "react-dom/server";
import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";
import NetworkTooltip from "./NetworkTooltip";
import Typography from "./Typography";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";
import TextButton from "./TextButton";
import Checkbox from "./Checkbox";
import { getIcon, getImageForNode } from "../../utils/commonUtils";

const NetworkGraph = ({
  handleSelectedNode,
  selectedNode,
  setSelectedNode,
  getPolicyDetails,
  networkGraphData,
}) => {
  const theme = useTheme();
  const [showFilters, setShowFilters] = useState(true);
  const [tooltipCoordinates, setTooltipCoordinates] = useState({ x: 0, y: 0 });
  const [showTooltip, setShowTooltip] = useState(null);
  const parentRef = useRef(null);

  const styles = {
    parentContainer: {
      display: "flex",
      width: "100%",
      height: "600px",
    },
    graphContainer: {
      width: "100%",
      height: "100%",
      position: "relative",
    },
    graphDiv: {
      flexGrow: 1,
      width: "700px",
      height: "500px",
    },
    filters: {
      minWidth: "25%",
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
    },
    filterBox: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
      width: "min-content",
      padding: theme.spacing(2),
      borderRadius: theme.spacing(0.5),
      border: `1px solid ${theme.palette.surface20.main}`,
    },
    filterTitle: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1),
    },
    filterIcon: {
      color: theme.palette.primaryBlue.main,
    },
    noWrap: {
      whiteSpace: "nowrap",
    },
    filterList: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(0.5),
    },
    filterCheckbox: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1),
    },
  };

  const drawNetworkGraph = () => {
    const element = d3.select(".networkGraph");
    element.selectAll("*").remove();
    const margin = { top: 50, left: 50, right: 50, bottom: 50 };

    const width = parseFloat(d3.select(`.networkGraph`).style("width"));
    const height = parseFloat(d3.select(`.networkGraph`).style("height"));

    const svg = element
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    const links = svg
      .selectAll("line")
      .data(networkGraphData.links)
      .enter()
      .append("line")
      .style("stroke", (d: any) =>
        d.isViolation
          ? theme.palette.critical.main
          : theme.palette.surface50.main
      )
      .style("stroke-width", 2);

    const nodes = svg
      .selectAll("g")
      .data(networkGraphData.nodes)
      .enter()
      .append("g")
      .attr("stroke", "red");

    const customNode: any = nodes
      .append("foreignObject")
      .attr("height", (d: any) => {
        const nodeProps = getImageForNode(d.type);
        return d.type === "langchain" ? nodeProps.height + 8 : nodeProps.height;
      })
      .attr("width", (d: any) => {
        const nodeProps = getImageForNode(d.type);
        return d.type === "langchain" ? nodeProps.width + 10 : nodeProps.width;
      })
      .on("click", (event, d: any) => {
        handleSelectedNode(d);
      })
      .on("mouseover", (event, d: any) => {
        if (d.id === showTooltip) {
          setShowTooltip(null);
          setTooltipCoordinates({ x: 0, y: 0 });
        } else {
          setTooltipCoordinates({ x: d.x, y: d.y });
          setShowTooltip(d);
        }
      })
      .on("mouseout", (event, d: any) => {
        setShowTooltip(null);
        setTooltipCoordinates({ x: 0, y: 0 });
      });

    var drag_controls = d3
      .drag()
      .on("start", dragstarted)
      .on("drag", dragged)
      .on("end", dragended);

    drag_controls(nodes as any);

    function dragstarted(event, d) {
      if (!event.active) simulation.alphaTarget(0.3).restart();
      d.fx = d.x;
      d.fy = d.y;
    }

    function dragged(event, d) {
      d.fx = event.x;
      d.fy = event.y;
      ticked();
    }

    function dragended(event, d) {
      if (!event.active) simulation.alphaTarget(0);
      d.fx = null;
      d.fy = null;
    }

    customNode.append("xhtml").html((d: any) => {
      const nodeProps = getIcon(d.type);
      const customNodeStructure =
        d.type === "langchain" ? (
          <div className={`nodeWrapper-${d?.id} node-hexagon-border`}>
            {d.isViolation && <div className="red-dot"></div>}
            <div className="node-hexagon">
              <img src={nodeProps} alt="node" height={30} width={30} />
            </div>
          </div>
        ) : (
          <div
            className={`nodeWrapper-${d?.id} node-rect ${
              d.type !== "users" ? "node-circle" : ""
            }`}
          >
            {d.isViolation && <div className="red-dot"></div>}
            <img src={nodeProps} alt="node" height={20} width={20} />
          </div>
        );
      return ReactDOMServer.renderToStaticMarkup(customNodeStructure);
    });

    const simulation = d3
      .forceSimulation(networkGraphData.nodes as any)
      .force(
        "link",
        d3
          .forceLink()
          .id(function (d: any) {
            return d.id;
          })
          .links(networkGraphData.links)
      )
      .force("collision", d3.forceCollide().radius(50))
      .force("centerX", d3.forceX(width / 2))
      .force("centerY", d3.forceY(height / 2))
      .on("tick", ticked);
    //  .stop();

    //for (let i = 0; i < 100; i++) simulation.tick();

    function ticked() {
      links
        .attr("x1", (d: any) => Math.max(5, Math.min(width - 5, d.source.x)))
        .attr("y1", (d: any) => Math.max(5, Math.min(height - 5, d.source.y)))
        .attr("x2", (d: any) => Math.max(5, Math.min(width - 5, d.target.x)))
        .attr("y2", (d: any) => Math.max(5, Math.min(height - 5, d.target.y)));
      customNode
        .attr("x", (d: any) => Math.max(5, Math.min(width - 5, d.x - 20)))
        .attr("y", (d: any) => Math.max(5, Math.min(height - 5, d.y - 20)));
    }

    // Custom force to put all nodes in a box
  };

  const showTooltipForNode = () => {
    const nodes = d3
      .select(".networkGraph")
      .selectAll("foreignObject")
      .on("click", (event, d: any) => {
        handleSelectedNode(d);
      })
      .on("mouseover", (event, d: any) => {
        if (d.id === showTooltip) {
          setShowTooltip(null);
          setTooltipCoordinates({ x: 0, y: 0 });
        } else {
          setTooltipCoordinates({ x: d.x, y: d.y });
          setShowTooltip(d);
        }
      })
      .on("mouseout", () => {
        setShowTooltip(null);
        setTooltipCoordinates({ x: 0, y: 0 });
      });
    d3.selectAll(".customNode").attr("stroke", "red");
  };

  useEffect(() => {
    drawNetworkGraph();
  }, []);

  useEffect(() => {
    showTooltipForNode();
  }, [showTooltip, selectedNode]);

  return (
    <Box sx={styles.parentContainer}>
      <Box sx={styles.filters}>
        <Box sx={styles.filterTitle}>
          <FilterListRoundedIcon sx={styles.filterIcon} />
          <Typography variant="body2" color={theme.palette.primaryBlue.main}>
            Filters
          </Typography>
          <TextButton onClick={() => setShowFilters((prevState) => !prevState)}>
            {showFilters ? (
              <ExpandLessRoundedIcon sx={styles.filterIcon} />
            ) : (
              <ExpandMoreRoundedIcon sx={styles.filterIcon} />
            )}
          </TextButton>
        </Box>
        {showFilters && (
          <Box sx={styles.filterBox}>
            <Typography
              variant="caption"
              color={theme.palette.surface60.main}
              sx={styles.noWrap}
            >
              Frameworks (3)
            </Typography>
            <Box sx={styles.filterList}>
              <Box sx={styles.filterCheckbox}>
                <Checkbox label="liveAccess" onClick={() => {}} />
                <Typography variant="caption" sx={styles.noWrap}>
                  With Policy Violations (1)
                </Typography>
              </Box>
              <Box sx={styles.filterCheckbox}>
                <Checkbox label="liveAccess" onClick={() => {}} />
                <Typography variant="caption" sx={styles.noWrap}>
                  More User Traffic (2)
                </Typography>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
      <Box sx={styles.graphContainer}>
        <div className="networkGraph" style={styles.graphDiv}></div>
        {showTooltip && (
          <NetworkTooltip
            getPolicyDetails={getPolicyDetails}
            tooltipData={showTooltip}
            tooltipCoordinates={tooltipCoordinates}
          />
        )}
      </Box>
    </Box>
  );
};

export default memo(NetworkGraph);
