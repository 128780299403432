import moment from "moment";
import LangchainIcon from "../assets/img/langchainIcon.svg";
import RDSIcon from "../assets/img/rds.svg";
import PDFIcon from "../assets/img/pdf.svg";
import PostgresIcon from "../assets/img/postgres.svg";
import GPTIcon from "../assets/img/gptIcon.svg";
import S3Icon from "../assets/img/AWS_S3.svg";
import VectorDBIcon from "../assets/img/chromaIcon.svg";
import HuggingIcon from "../assets/img/hugging.png";
import GoogleDriveIcon from "../assets/img/google-drive.png";
import NotionIcon from "../assets/img/notion.png";
import UsersIcon from "../assets/img/usersIcon.svg";
import SlackIcon from "../assets/img/slack.png";
import SharePointLoader from "../assets/img/sharepoint.png";
import ChromaIcon from "../assets/img/chromaIcon.svg";
import PineconeIcon from "../assets/img/pinecone.svg";
import GPCIcon from "../assets/img/gcp.svg";
import AWSIcon from "../assets/img/awsIcon.svg";
import MDIcon from "../assets/img/md.svg";
import DocIcon from "../assets/img/docx-icon.svg";

const commonUtils = {};
export default commonUtils;

/**
 *
 * @param date1
 * @param date2
 * @returns returns difference between two dates in days
 */
export const getDifferenceInDays = (date1: Date, date2: Date) => {
  // Convert both dates to milliseconds
  const timeDiff = Math.abs(date2.getTime() - date1.getTime());

  // Convert milliseconds to days
  const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

  return diffDays;
};

/**
 *
 * @param number
 * @returns number with 0 prefix if less than 10
 */
export const prefixNumber = (number: number) => {
  return number > 0 && number < 10 ? `0${number}` : number;
};

/**
 *
 * @param object
 * @returns checks weather object contains any value or not
 */
export const isContainsValue = (data: object) => {
  if (data && Object.values(data)?.length > 0) {
    return true;
  }
  return false;
};

/**
 *
 * @param object
 * @returns checks weather string contains word vulnerable and returns string without vulnerable
 */
export const getFormattedTitle = (title: string) => {
  if (title.toLocaleLowerCase().includes("vulnerable")) {
    return title.replace("Vulnerable ", "");
  }
  return title;
};

/**
 *
 * @param num
 * @returns number abbreviation
 */
export const getNumberAbbreviation = (num) => {
  if (num > 9999) {
    if (num < 1e6) return +(num / 1e3).toFixed(1) + "K";
    if (num >= 1e6 && num < 1e9) return +(num / 1e6).toFixed(1) + "M";
  }
  return num.toString();
};

/**
 *
 * @param str
 * @returns remove all the special characters
 */
export const removeSpecialCharacters = (str) => {
  return str && str !== "" ? str.replace(/[^a-zA-Z0-9 ]/g, "") : "";
};

/**
 * object property name to formatted string
 * @param title
 * @returns formatted string
 */
export const formatTitle = (title) => {
  let formatedTitle = title.replaceAll("_", " ");
  let arr = formatedTitle.split(" ");

  for (var index = 0; index < arr.length; index++) {
    arr[index] = arr[index].charAt(0).toUpperCase() + arr[index].slice(1);
  }
  return arr.join(" ");
};

/**
 * convert bytes to GB
 * @param bytes
 * @param decimals
 * @returns
 */
export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const isDataWithinDefinedDate = (dateKeyword, timestamp) => {
  const dateToCompare = moment(timestamp);
  const todaysDate = moment();

  switch (dateKeyword) {
    case "7days": {
      const diff = moment(todaysDate).diff(dateToCompare, "days");
      return diff <= 7;
    }
    case "1day": {
      const diff = moment(todaysDate).diff(dateToCompare, "days");
      return diff === 1;
    }
    case "1hour": {
      const diff = moment(todaysDate).diff(dateToCompare, "hours");
      return diff === 1;
    }
    default: {
      return false;
    }
  }
};

export const isToday = (date) => {
  const today = moment().format("YYYY-MM-DD");
  const dateInQuestion = moment(date).format("YYYY-MM-DD");
  return today === dateInQuestion;
};

export const getSelectedAccountIds = (selectedAccounts) => {
  let accountIds: Array<string> = [];
  if (selectedAccounts?.length > 0) {
    selectedAccounts.map((account: { value: string }) => {
      accountIds.push(account.value);
    });
  }
  return accountIds;
};

export const getImageForNode = (type) => {
  switch (type) {
    case "langchain": {
      return {
        width: 50,
        height: 50,
        imgSrc: LangchainIcon,
      };
    }
    case "rds": {
      return {
        width: 40,
        height: 40,
        imgSrc: RDSIcon,
      };
    }
    case "s3": {
      return {
        width: 40,
        height: 40,
        imgSrc: S3Icon,
      };
    }
    case "llm": {
      return {
        width: 40,
        height: 40,
        imgSrc: HuggingIcon,
      };
    }
    case "pdf": {
      return {
        width: 40,
        height: 40,
        imgSrc: PDFIcon,
      };
    }
    case "postgres": {
      return {
        width: 40,
        height: 40,
        imgSrc: PostgresIcon,
      };
    }
    case "vectorDB": {
      return {
        width: 40,
        height: 40,
        imgSrc: VectorDBIcon,
      };
    }
    case "Notion": {
      return {
        width: 40,
        height: 40,
        imgSrc: NotionIcon,
      };
    }
    case "GoogleDrive": {
      return {
        width: 40,
        height: 40,
        imgSrc: GoogleDriveIcon,
      };
    }
    case "users": {
      return {
        width: 40,
        height: 40,
        imgSrc: UsersIcon,
      };
    }
    default:
      return {
        width: 40,
        height: 40,
        imgSrc: GPTIcon,
      };
  }
};

export const getMMMDDYYYY = (date) => {
  return moment(date).format("MMM DD, YYYY");
};

export const getDateTime = (date) => {
  return date ? moment(date).format("MMM DD, YYYY, hh:mm A") : "";
};

export const convertUTCToLocal = (date) => {
  if (date) {
    return moment.utc(date).local();
  }
  return "";
};

export const getTruncatedString = (str, length, showMore) => {
  if (showMore) {
    return str.substring(0, str.length);
  }
  return str?.length > length ? str.substring(0, length) + "..." : str;
};

export const getDDMMYYYY = (date) => {
  return moment(date).format("DD MMM YYYY");
};

export const getStringFromArray = (arr, showCount = 2) => {
  const inputArr = [...arr];
  if (inputArr) {
    if (inputArr?.length > showCount) {
      return (
        inputArr?.splice(0, showCount)?.join(", ") +
        ` +${arr?.length - showCount}`
      );
    }
    return inputArr?.join(", ");
  }
  return "";
};

export const modifyStringStartAndEnd = (str) => {
  if (str.length > 40) {
    const firstChars = str.slice(0, 10); // => "Tabs1"
    const lastChars = str.substr(str.length - 15);
    return firstChars + "..." + lastChars;
  }
  return str;
};

export const getFileName = (name) => {
  if (name) {
    return name.split("/")?.at(-1);
  }
  return "";
};

export const getIcon = (name) => {
  const lowerCaseName = name.toLowerCase();
  switch (true) {
    case lowerCaseName.includes("pinecone"):
      return PineconeIcon;
    case lowerCaseName.includes("chroma"):
      return ChromaIcon;
    case lowerCaseName.includes("langchain"):
      return LangchainIcon;
    case lowerCaseName.includes("rds"):
      return RDSIcon;
    case lowerCaseName.includes("postgres"):
      return PostgresIcon;
    case lowerCaseName.includes("gpt"):
      return GPTIcon;
    case lowerCaseName.includes("s3"):
      return S3Icon;
    case lowerCaseName.includes("hugging"):
      return HuggingIcon;
    case lowerCaseName.includes("google"):
      return GoogleDriveIcon;
    case lowerCaseName.includes("notion"):
      return NotionIcon;
    case lowerCaseName.includes("slack"):
      return SlackIcon;
    case lowerCaseName.includes("sharepoint"):
      return SharePointLoader;
    case lowerCaseName.includes("llm"):
      return HuggingIcon;
    case lowerCaseName.includes("users"):
      return UsersIcon;
    case lowerCaseName.includes("openai"):
      return GPTIcon;
    case lowerCaseName.includes("aws"):
      return AWSIcon;
    case lowerCaseName.includes("gcp"):
      return GPCIcon;
    case lowerCaseName.includes("pdf"):
      return PDFIcon;
    case lowerCaseName.includes("md"):
      return MDIcon;
    case lowerCaseName.includes("doc"):
      return DocIcon;
    default:
      return "";
  }
};

export const generateRandomDate = (start, end) => {
  //Generate a random date between start and end with format YYYY-MM-DD HH:MM:SS
  start = new Date(start);
  end = new Date(end);
  return moment(
    start.getTime() + Math.random() * (end.getTime() - start.getTime())
  ).format("YYYY-MM-DD HH:MM:SS");
};

export const capitalizeFirstLetter = (string) => {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
};

export const mergeArrayByProperty = (data) => {
  // Flatten the input array
  const flattenedData = data ? data.flat() : [];

  // Create a merged object by SensitiveLabel
  const mergedData = flattenedData.reduce((acc, current) => {
    const { sensitiveLabel, labelType, messages } = current;
    if (!acc[sensitiveLabel]) {
      acc[sensitiveLabel] = {
        sensitiveLabel,
        labelType,
        messages: [...messages],
      };
    } else {
      acc[sensitiveLabel].messages.push(...messages);
    }
    return acc;
  }, {});

  // Convert the merged object back to an array
  return Object.values(mergedData);
};
