export const datasources = [
  {
    name: "Proxima - SupportApp",
    chains: [
      {
        name: "PebbloRetrievalQA",
        vectorDbs: [
          {
            name: "Pinecone",
            version: "3.0.3",
            embeddingModel: "OpenAI - text-embedding-3-small",
            location: "/var/rag/langchain/chroma_db",
            bucket: [
              {
                name: "SharePoint Loader",
                sensitiveData: "PII-SSN, Credit Cards, PII-SSN, Email",
                type: "sharepoint",
              },
              {
                name: "Slack API Loader",
                sensitiveData: "PII-SSN, Credit Cards, PII-SSN, Email",
                type: "slack",
              },
            ],
            packageInfo: {
              projectHomePage: "home",
              documentationUrl: "https://docs.trychroma.com",
              pypiUrl: "https://pypi.org/project/chromadb",
              licenceType: "Apache Software License",
              installedVia: "PIP installation",
              location: "/var/rag/langchain/chroma_db",
            },
          },
        ],
        model: {
          name: "GPT-3.5 Turbo",
          vendor: "OpenAI",
        },
      },
    ],
    summary: {
      family: "langchain",
      name: "Proxima - SupportApp",
      version: "0.1.7",
      vectorDB: "Pinecone",
      location: "support-kb-index",
      embeddingModel: "OpenAI - text-embedding-3-small",
      models: {
        name: "GPT-3.5 Turbo",
        vendor: "OpenAI",
      },
    },
  },
];
