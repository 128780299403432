import moment from "moment";

export const documents = [
  {
    name: "channels: #support",
    type: "Slack",
    confidentialityTag: "Default",
    path: "org: acme-corp.slack.com, channels: #support",
    source: "Slack",
    aiInsight:
      "Multiple AWS Access Key has been ingested from Slack Channel: #support. Create a ticket to clean the document source and re-ingest.",
    database: {
      name: "Pinecone",
      updatedAt:
        moment().subtract(1, "days").format("YYYY-MM-DD") + " 10:43:12",
    },
    size: "304.52 MB",
    sensitiveData: {
      value: 12,
      outOf: 16,
    },
    overprovisioned: 0,
    sensitiveContent: {
      value: 5,
      outOf: 30,
      isHarmful: true,
    },

    snippetImpact: {
      tag: "Sensitive",
    },
    harmfulLabels: [
      {
        name: "Bias",
        count: 1,
      },
    ],
    sensitiveLabels: [
      {
        name: "AWS Access Key",
        count: 8,
      },
      {
        name: "Github Token",
        count: 3,
      },
      {
        name: "Financial",
        count: 1,
      },
    ],
    policyViolations: ["Sensitive Identifier in Prompt Session"],
    authorizedGroups: [
      "us_support_group@acmecorp.com",
      "us_intern_group@acmecorp.com",
    ],
  },
  {
    name: "support-kb.pdf",
    type: "pdf",
    path: "https://acme-corp.sharepoint.com/support-portal/documents/support-kb.pdf",
    source: "SharePoint",
    confidentialityTag: "Internal",
    database: {
      name: "Pinecone",
      updatedAt:
        moment().subtract(1, "days").format("YYYY-MM-DD") + " 17:43:19",
    },
    size: "67.53 MB",
    sensitiveData: {
      value: 7,
      outOf: 10,
    },
    sensitiveContent: {
      value: 2,
      outOf: 4,
      isHarmful: true,
    },
    snippetImpact: {
      tag: "Harmful",
      value: 3,
    },
    harmfulLabels: [
      {
        name: "Racial Bias",
        count: 3,
      },
    ],
    sensitiveLabels: [
      {
        name: "EC Private Key",
        count: 1,
      },
      {
        name: "Github Token",
        count: 3,
      },
      {
        name: "Financial",
        count: 2,
      },
      { name: "Product Roadmap", count: 1 },
    ],
    policyViolations: ["Sensitive Identifier in Prompt Session"],
    authorizedGroups: ["us_support_group@acmecorp.com"],
  },
  {
    name: "historical-loans.pdf",
    type: "pdf",
    path: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
    source: "Google Drive",
    confidentialityTag: "Internal",
    database: { name: "postgres", updatedAt: "2024-09-20 10:43:12" },
    size: "127.9 MB",
    sensitiveData: {
      value: 16,
      outOf: 46,
    },
    sensitiveContent: {
      value: 13,
      outOf: 56,
      isHarmful: true,
    },
    snippetImpact: {
      tag: "",
      labels: ["Toxic Content", "Racial Bias", "Insensitive Content"],
    },
    harmfulLabels: [
      {
        name: "Bias",
        count: 1,
      },
    ],
    sensitiveLabels: [
      {
        name: "PII - SSN",
        count: 2,
      },
    ],
    policyViolations: ["Sensitive Identifier in Prompt Session"],
    authorizedGroups: [
      "us_employee_group@acmecorp.com",
      "sales_group@acmecorp.com",
    ],
  },
  {
    name: "acme-feature-roadmap-2024Q4.docx",
    type: "docx",
    path: "https://acme-corp.sharepoint.com/support-portal/documents/acme-feature-roadmap-2024Q4.docx",
    source: "SharePoint",
    confidentialityTag: "Confidential",
    database: {
      name: "Pinecone",
      updatedAt:
        moment().subtract(1, "days").format("YYYY-MM-DD") + " 19:31:52",
    },
    size: "28.92 MB",
    sensitiveData: {
      value: 3,
      outOf: 3,
    },
    sensitiveContent: {
      value: 0,
      outOf: 3,
      isHarmful: false,
    },
    snippetImpact: {
      tag: "Sensitive",
      value: 3,
    },
    harmfulLabels: [],
    sensitiveLabels: [{ name: "Product Roadmap", count: 3 }],
    policyViolations: ["Sensitive Identifier in Prompt Session"],
    authorizedGroups: ["us_support_group@acmecorp.com"],
  },
  {
    name: "product-kb.md",
    type: "md",
    path: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
    source: "Notion DB",
    confidentialityTag: "Highly Confidential",
    database: { name: "Chroma DB", updatedAt: "2024-09-20 10:43:12" },
    size: "34.52 MB",
    sensitiveData: {
      value: 12,
      outOf: 39,
    },
    sensitiveContent: {
      value: 5,
      outOf: 26,
      isHarmful: true,
    },
    snippetImpact: {
      tag: "",
      labels: [],
    },
    harmfulLabels: [],
    sensitiveLabels: [
      {
        name: "Product Roadmap",
        count: 12,
      },
    ],
    policyViolations: ["Sensitive Identifier in Prompt Session"],
    authorizedGroups: ["pm_group@acmecorp.com"],
  },
  {
    name: "health-advice.pdf",
    type: "pdf",
    path: "s3://health-data-etl-38738/kb/health-advice.pdf",
    source: "S3",
    confidentialityTag: "Confidential",
    database: { name: "pinecone", updatedAt: "2024-09-20 10:43:12" },
    size: "65.29 MB",
    sensitiveData: {
      value: 6,
      outOf: 28,
    },
    sensitiveContent: {
      value: 4,
      outOf: 12,
      isHarmful: true,
    },
    snippetImpact: {
      tag: "",
      labels: [],
    },
    harmfulLabels: [],
    sensitiveLabels: [
      {
        name: "Medical Advice",
        count: 36,
      },
      {
        name: "PHI - MRN",
        count: 5,
      },
    ],
    policyViolations: ["Sensitive Identifier in Prompt Session"],
    authorizedGroups: ["us_support_group@acmecorp.com"],
  },
];
