export const treeMapData = [
  {
    appName: "ProductInsights",
    mapData: [
      { name: "P1", value: "", parent: "", user: "P1" },
      {
        name: "Notion_DB/product-table/product-kb.md",
        value: "16",
        parent: "P1",
        user: ["John Doe"],
        topics: ["Product Roadmap"],
      },
      {
        name: "Notion_DB/product-table/product-1.md",
        value: "15",
        parent: "P1",
        user: ["Tom Platt"],
        topics: ["Product Roadmap"],
      },
      {
        name: "Notion_DB/product-table/product-new.md",
        value: "10",
        parent: "P1",
        user: ["Tom Platt"],
      },
      {
        name: "Notion_DB/product-table/file5.txt",
        value: "5",
        parent: "P1",
        user: ["John Doe"],
      },
      {
        name: "Notion_DB/product-table/file2.txt",
        value: "3",
        parent: "P1",
        user: ["Tom Platt"],
      },
      {
        name: "/Notion_DB/product-tablefile6.txt",
        value: "1",
        parent: "P1",
        user: ["John Doe"],
      },
      {
        name: "/Notion_DB/product-table/file16.txt",
        value: "1",
        parent: "P1",
        user: ["John Doe"],
      },
      {
        name: "Notion_DB/product-table/file9.txt",
        value: "1",
        parent: "P1",
        user: ["Tom Platt"],
      },
      {
        name: "Notion_DB/product-table/file14.txt",
        value: "1",
        parent: "P1",
        user: ["John Doe"],
      },
      {
        name: "Notion_DB/product-table/file1.txt",
        value: "1",
        parent: "P1",
        user: ["Tom Platt"],
      },
      {
        name: "Notion_DB/product-table/file18.txt",
        value: "1",
        parent: "P1",
        user: ["John Doe"],
      },
      //  { name: "file4.txt", value: "1", parent: "P1", user: ["John Doe"] },
      //  { name: "file11.txt", value: "1", parent: "P1", user: ["Tom Platt"] },
      //  { name: "file17.txt", value: "1", parent: "P1", user: ["John Doe"] },
      //  { name: "file8.txt", value: "1", parent: "P1", user: ["John Doe"] },
      {
        name: "Notion_DB/product-table/file19.txt",
        value: "1",
        parent: "P1",
        user: ["John Doe"],
      },
      {
        name: "Notion_DB/product-table/file7.txt",
        value: "1",
        parent: "P1",
        user: ["John Doe"],
      },
      //  { name: "file3.txt", value: "1", parent: "P1", user: ["John Doe"] },
      //  { name: "file13.txt", value: "1", parent: "P1", user: ["John Doe"] },
    ],
    usersMapData: [
      { name: "P1", value: "", parent: "", user: "P1" },
      {
        name: "John Doe",
        value: "25",
        parent: "P1",
        user: ["John Doe"],
        files: 6,
        topics: ["Product Roadmap"],
      },
      {
        name: "Tom Platt",
        value: "16",
        parent: "P1",
        user: ["Tom Platt"],
        files: 7,
        topics: ["Product Roadmap"],
      },
    ],
  },
  {
    appName: "AcmeMortgageApp-1",
    mapData: [
      { name: "P1", value: "", parent: "", user: "P1" },
      {
        id: "1",
        name: "https://drive.google.com/...../historical-loans.pdf",
        fileName:
          "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
        value: "18",
        parent: "P1",
        user: ["John Doe"],
        topics: ["Intellectual Property"],
      },
      {
        id: "3",
        name: "https://drive.google.com/...../mortgage-process.pdf",
        fileName:
          "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/mortgage-process.pdf",
        value: "14",
        parent: "P1",
        user: ["Tom Platt"],
        topics: ["Intellectual Property"],
      },
      {
        id: "4",
        name: "https://drive.google.com/...../mortgage-financial.pdf",
        fileName:
          "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
        value: "9",
        parent: "P1",
        user: ["John Doe"],
        topics: ["Intellectual Property"],
      },
      {
        id: "2",
        fileName:
          "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/mortgage_loans.pdf",
        name: "https://drive.google.com/...../mortgage_loans.pdf",
        value: "5",
        parent: "P1",
        user: ["Tom Platt"],
        topics: ["Intellectual Property"],
      },
      {
        id: "5",
        name: "https://drive.google.com/..../file2.txt",
        value: "3",
        parent: "P1",
        user: ["Tom Platt"],
      },
      {
        id: "6",
        name: "https://drive.google.com/...../file6.txt",
        value: "2",
        parent: "P1",
        user: ["John Doe"],
      },
      {
        id: "7",
        name: "https://drive.google.com/file16.txt",
        value: "1",
        parent: "P1",
        user: ["John Doe"],
      },
      {
        id: "8",
        name: "https://drive.google.com/file9.txt",
        value: "1",
        parent: "P1",
        user: ["Tom Platt"],
      },
      {
        id: "9",
        name: "https://drive.google.com/file14.txt",
        value: "1",
        parent: "P1",
        user: ["John Doe"],
      },
      {
        id: "10",
        name: "https://drive.google.com/file1.txt",
        value: "1",
        parent: "P1",
        user: ["Tom Platt"],
      },
      {
        id: "11",
        name: "https://drive.google.com/..../file18.txt",
        value: "1",
        parent: "P1",
        user: ["John Doe"],
      },
      //  { name: "file4.txt", value: "1", parent: "P1", user: ["John Doe"] },
      //  { name: "file11.txt", value: "1", parent: "P1", user: ["Tom Platt"] },
      //  { name: "file17.txt", value: "1", parent: "P1", user: ["John Doe"] },
      //  { name: "file8.txt", value: "1", parent: "P1", user: ["John Doe"] },
      {
        id: "12",
        name: "https://drive.google.com/file19.txt",
        value: "1",
        parent: "P1",
        user: ["John Doe"],
      },
      {
        id: "13",
        name: "https://drive.google.com/file7.txt",
        value: "1",
        parent: "P1",
        user: ["John Doe"],
      },
      //  { name: "file3.txt", value: "1", parent: "P1", user: ["John Doe"] },
      //  { name: "file13.txt", value: "1", parent: "P1", user: ["John Doe"] },
    ],
    usersMapData: [
      { name: "P1", value: "", parent: "", user: "P1" },
      {
        id: "3",
        name: "John Doe",
        value: "15",
        parent: "P1",
        violation: false,
        user: ["John Doe"],
        files: 6,
        topics: ["Product Roadmap"],
      },
      {
        id: "1",
        name: "Tom Platt",
        value: "12",
        parent: "P1",
        violation: true,
        user: ["Tom Platt"],
        files: 5,
        topics: ["Product Roadmap"],
      },
      {
        id: "2",
        name: "Alex Smith",
        value: "10",
        parent: "P1",
        violation: true,
        user: ["Alex Smith"],
        files: 2,
        topics: ["Product Roadmap"],
      },
    ],
  },
  {
    appName: "HealthCareApp-1",
    mapData: [
      { name: "P1", value: "", parent: "", user: "P1" },
      {
        id: "1",
        name: "s3://health-data-etl-38738/kb/health-advice.pdf",
        value: "15",
        parent: "P1",
        user: ["John Doe"],
        topics: ["Medical Advice"],
      },
      {
        id: "2",
        name: "s3://health-data-etl-38738/kb/health-records.json",
        value: "10",
        parent: "P1",
        user: ["Tom Platt"],
        topics: ["Medical Advice"],
      },
      {
        id: "3",
        name: "s3://health-data-etl-38738/kb/health-data.pdf",
        value: "9",
        parent: "P1",
        user: ["Tom Platt"],
        topics: ["Medical Advice"],
      },
      {
        id: "4",
        name: "s3://health-data-etl-38738/file5.txt",
        value: "1",
        parent: "P1",
        user: ["John Doe"],
        topics: ["Medical Advice"],
      },
      {
        id: "5",
        name: "s3://health-data-etl-38738/file2.txt",
        value: "1",
        parent: "P1",
        user: ["Tom Platt"],
        topics: ["Medical Advice"],
      },
      {
        id: "6",
        name: "s3://health-data-etl-38738/product-tablefile6.txt",
        value: "1",
        parent: "P1",
        user: ["John Doe"],
        topics: ["Medical Advice"],
      },
      {
        id: "7",
        name: "s3://health-data-etl-38738/file16.txt",
        value: "1",
        parent: "P1",
        user: ["John Doe"],
        topics: ["Medical Advice"],
      },
      {
        id: "8",
        name: "s3://health-data-etl-38738/file9.txt",
        value: "1",
        parent: "P1",
        user: ["Tom Platt"],
        topics: ["Medical Advice"],
      },
      {
        id: "9",
        name: "s3://health-data-etl-38738/file14.txt",
        value: "1",
        parent: "P1",
        user: ["John Doe"],
        topics: ["Medical Advice"],
      },
      {
        id: "10",
        name: "s3://health-data-etl-38738/file1.txt",
        value: "1",
        parent: "P1",
        user: ["Tom Platt"],
      },

      //  { name: "file4.txt", value: "1", parent: "P1", user: ["John Doe"] },
      //  { name: "file11.txt", value: "1", parent: "P1", user: ["Tom Platt"] },
      //  { name: "file17.txt", value: "1", parent: "P1", user: ["John Doe"] },
      //  { name: "file8.txt", value: "1", parent: "P1", user: ["John Doe"] },
      {
        id: "11",
        name: "Notion_DB/product-table/file19.txt",
        value: "1",
        parent: "P1",
        user: ["John Doe"],
      },
      {
        id: "12",
        name: "Notion_DB/product-table/file7.txt",
        value: "1",
        parent: "P1",
        user: ["John Doe"],
      },
      //  { name: "file3.txt", value: "1", parent: "P1", user: ["John Doe"] },
      //  { name: "file13.txt", value: "1", parent: "P1", user: ["John Doe"] },
    ],
    usersMapData: [
      { name: "P1", value: "", parent: "", user: "P1" },
      {
        id: "1",
        name: "John Doe",
        value: "21",
        parent: "P1",
        user: ["John Doe"],
        files: 6,
        topics: ["Product Roadmap"],
      },
      {
        id: "2",
        name: "Tom Platt",
        value: "20",
        parent: "P1",
        user: ["Tom Platt"],
        files: 7,
        topics: ["Product Roadmap"],
      },
    ],
  },
  {
    appName: "Proxima - SupportApp",
    mapData: [
      { name: "P1", value: "", parent: "", user: "P1" },

      {
        id: "2",
        name: "org: acme-corp.slack.com, channels: #support",
        value: "17",
        parent: "P1",
        user: ["John Doe"],
        topics: ["Product Roadmap"],
      },
      {
        id: "1",
        name: "https://acme-corp.sharepoint.com/support-portal/documents/support-kb.pdf",
        fileName:
          "https://acme-corp.sharepoint.com/support-portal/documents/support-kb.pdf",
        value: "4",
        parent: "P1",
        user: ["John Doe"],
        topics: ["Product Roadmap"],
      },
      {
        id: "1",
        name: "https://acme-corp.sharepoint.com/support-portal/documents/acme-feature-roadmap-2024Q4.docx",
        fileName:
          "https://acme-corp.sharepoint.com/support-portal/documents/acme-feature-roadmap-2024Q4.docx",
        value: "2",
        parent: "P1",
        user: ["John Doe"],
        topics: ["Product Roadmap"],
      },
    ],
    usersMapData: [
      { name: "P1", value: "", parent: "", user: "P1" },
      {
        id: "2",
        name: "Alex Smith",
        value: "13",
        parent: "P1",
        violation: true,
        user: ["Alex Smith"],
        files: 2,
        topics: ["Product Roadmap"],
      },
      {
        id: "3",
        name: "Tom Platt",
        value: "5",
        parent: "P1",
        user: ["Tom Platt"],
        files: 5,
        topics: ["Product Roadmap"],
      },
      {
        id: "1",
        name: "John Doe",
        value: "4",
        parent: "P1",
        user: ["John Doe"],
        files: 8,
        topics: ["Product Roadmap"],
      },
    ],
  },
];
