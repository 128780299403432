import { useEffect, useState } from "react";
import Box from "../../components/common/Box";
import { useParams } from "react-router";
import { Breadcrumbs, Link } from "../../components/common/Breadcrumbs";
import { IconButton, useTheme } from "@mui/material";
import Typography from "../../components/common/Typography";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import GroupsIcon from "../../assets/img/groupsIcon.svg";
import TextButton from "../../components/common/TextButton";
import Divider from "../../components/common/Divider";
import { retrievalUserDetails } from "../../mock-data/retrievalUserDetails";
import LangchainApp from "../../assets/img/langchainApp.svg";
import LegendWithColor from "../../components/common/LegendWithColor";
import UserRetreivals from "./UserRetrievals";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import RefreshIcon from "@mui/icons-material/Refresh";
import SpectrumGraph from "../../components/common/SpectrumGraph";
import { useHistory } from "react-router-dom";

const UserDetailsPage = () => {
  const [userDetails, setUserDetails] = useState<any>(null);
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(menuAnchor);
  const params = useParams();
  const theme = useTheme();
  const history = useHistory();
  const styles = {
    page: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
    },
    activeLink: { color: theme.palette.surface80.main },
    blueLink: {
      color: theme.palette.primaryBlue.main,
    },
    breadCrumbLink: {
      fontSize: theme.typography.caption,
    },
    userHeader: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1.5),
    },
    iconWrapper: {
      width: "fit-content",
      border: `1px solid ${theme.palette.surface40.main}`,
      borderRadius: "1px",
      padding: "2px",
    },
    userIcon: {
      height: "40px",
      width: "40px",
    },
    userContent: {
      width: "100%",
      display: "flex",
    },
    actions: {
      display: "flex",
      justifyContent: "space-between",
      gap: theme.spacing(2),
      marginLeft: "auto",
      alignItems: "center",
    },
    userInfo: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(0.5),
      justifyContent: "center",
    },
    font20: {
      fontSize: "20px",
      fontWeight: theme.typography.fontWeightMedium,
    },
    font13: {
      fontSize: "13px",
    },
    userDetails: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(4),
      backgroundColor: "rgba(19, 22, 41, 0.6)",
      borderRadius: "4px",
      padding: theme.spacing(2),
    },
    retrievalDetails: {
      display: "flex",
      justifyContent: "space-between",
    },
    group: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
    },
    groupTitle: {
      display: "flex",
      gap: theme.spacing(1),
      alignItems: "baseline",
    },
    row: {
      display: "flex",
      gap: theme.spacing(1),
      alignItems: "center",
    },
    groupCount: {
      fontFamily: "Manrope, sans-serif",
      fontSize: "24px",
    },
    retrievalContent: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2.5),
    },
    groupContent: {},
    pageBody: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(4),
    },
    iconButton: {
      height: "fit-content",
      width: "fit-content",
    },
    menuIcon: {
      fontSize: theme.spacing(2.5),
      color: theme.palette.primaryBlue.main,
    },
    divider: {
      backgroundColor: theme.palette.surface20.main,
    },
  };

  const refreshPage = () => {
    history.go(0);
  };

  useEffect(() => {
    const { userName } = params;
    const user = retrievalUserDetails.find((user) => user.name === userName);
    setUserDetails(user);
  }, []);

  return (
    <Box sx={styles.page}>
      <Breadcrumbs sx={styles.breadCrumbLink}>
        <Link underline="hover" color="inherit" href="/" sx={styles.blueLink}>
          Home
        </Link>
        <Link
          underline="hover"
          color="inherit"
          href="/users"
          sx={styles.blueLink}
        >
          Active Users
        </Link>
        <Typography variant="caption" sx={styles.activeLink}>
          {userDetails?.name}
        </Typography>
      </Breadcrumbs>
      {userDetails && (
        <Box sx={styles.pageBody}>
          <Box sx={styles.userDetails}>
            <Box sx={styles.userHeader}>
              <Box sx={styles.iconWrapper}>
                <AccountBoxIcon sx={styles.userIcon} />
              </Box>
              <Box sx={styles.userContent}>
                <Box sx={styles.userInfo}>
                  <Typography sx={styles.font20}>{userDetails.name}</Typography>
                  <Typography
                    variant="caption"
                    color={theme.palette.surface50.main}
                  >
                    {userDetails.designation}
                  </Typography>
                </Box>
                <Box sx={styles.actions}>
                  <TextButton startIcon={<RefreshIcon />} onClick={refreshPage}>
                    Refresh
                  </TextButton>
                  <IconButton
                    onClick={(e) => setMenuAnchor(e.currentTarget)}
                    sx={styles.iconButton}
                  >
                    <MoreVertIcon sx={styles.menuIcon} />
                  </IconButton>
                </Box>
              </Box>
            </Box>
            <Box sx={styles.retrievalDetails}>
              <Box sx={styles.group}>
                <Box sx={styles.groupTitle}>
                  <Typography
                    sx={styles.groupCount}
                    color={theme.palette.surface80.main}
                  >
                    {userDetails?.groups?.length}
                  </Typography>
                  <Typography
                    sx={styles.font13}
                    color={theme.palette.surface80.main}
                  >
                    Groups
                  </Typography>
                </Box>
                <Box sx={styles.groupContent}>
                  {userDetails.groups.map((group) => (
                    <Box sx={styles.row}>
                      <img src={GroupsIcon} width="16px" />
                      <Typography
                        variant="subtitle2"
                        color={theme.palette.surface70.main}
                      >
                        {group}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
              <Divider orientation="vertical" flexItem sx={styles.divider} />
              <Box sx={styles.group}>
                <Box sx={styles.groupTitle}>
                  <Typography
                    sx={styles.groupCount}
                    color={theme.palette.surface80.main}
                  >
                    {userDetails?.apps?.length}
                  </Typography>
                  <Typography
                    sx={styles.font13}
                    color={theme.palette.surface80.main}
                  >
                    Applications
                  </Typography>
                </Box>
                <Box sx={styles.groupContent}>
                  {userDetails?.apps?.map((group) => (
                    <Box sx={styles.row}>
                      <img src={LangchainApp} width="30px" />
                      <Typography
                        variant="subtitle2"
                        color={theme.palette.surface70.main}
                      >
                        {group}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
              <Divider orientation="vertical" flexItem sx={styles.divider} />
              <Box sx={styles.group}>
                <Box sx={styles.groupTitle}>
                  <Typography
                    sx={styles.groupCount}
                    color={theme.palette.surface80.main}
                  >
                    {userDetails?.retrievals?.reduce((acc, curr) => {
                      return acc + curr?.value;
                    }, 0)}
                  </Typography>
                  <Typography
                    sx={styles.font13}
                    color={theme.palette.surface80.main}
                  >
                    Retreivals
                  </Typography>
                </Box>
                <Box sx={styles.retrievalContent}>
                  <SpectrumGraph
                    width={369}
                    height={18}
                    data={userDetails.retrievals}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      gap: theme.spacing(1),
                      flexWrap: "wrap",
                      width: "90%",
                    }}
                  >
                    {userDetails?.retrievals?.map((retrieval) => (
                      <LegendWithColor
                        color={retrieval?.color}
                        label={`${retrieval?.label} (${retrieval?.value})`}
                        textColor={theme.palette.surface70.main}
                      />
                    ))}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <UserRetreivals user={userDetails} />
        </Box>
      )}
    </Box>
  );
};

export default UserDetailsPage;
