import Box from "../../common/Box";
import Divider from "../../common/Divider";
import KeyValueBlock from "../../common/KeyValueBlock";
import TextButton from "../../common/TextButton";
import Typography from "../../common/Typography";
import LangchainIcon from "../../../assets/img/langchainIcon.svg";
import LangchainHexagonIcon from "../../../assets/img/langchainHexagonIcon.svg";
import AWSIcon from "../../../assets/img/awsIcon.svg";
import ChromaIcon from "../../../assets/img/chromaIcon.svg";
import GptIcon from "../../../assets/img/gptIcon.svg";
import GCPIcon from "../../../assets/img/gcp.svg";
import DesktopIcon from "../../../assets/img/desktopIcon.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { IconButton, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  convertUTCToLocal,
  getDateTime,
  getDDMMYYYY,
  getDifferenceInDays,
  getIcon,
  getTruncatedString,
} from "../../../utils/commonUtils";

import { useDispatch } from "react-redux";
import { getCustomerId } from "../../../utils/SessionHelper";
import { useParams } from "react-router-dom";
import { TOASTER_TYPES, Toaster } from "../../../utils/toaster";
// import { DeleteApplicationDialog } from "./DeleteApplicationDialog";
import { useHistory } from "react-router-dom";
import PostAddIcon from "@mui/icons-material/PostAdd";
import AddDescriptionDialog from "../AddDescriptionDialog";
import { Menu } from "../../common/Menu";
import { MenuItem } from "../../common/Select";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteOutlineOutlined from "@mui/icons-material/DeleteOutlineOutlined";
import EditIcon from "@mui/icons-material/Edit";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "../../common/Accordion";
import PostgresIcon from "../../../assets/img/postgres.svg";
import HuggingFaceIcon from "../../../assets/img/hugging.png";
import ViewMoreBlock from "../../common/ViewMoreBlock";
import moment from "moment";
import { frameworkData as frameworkJSONData } from "../../../mock-data/frameworkData";

const NUM_OF_DEFAULT_CHARACTERS = 100;

const LLMFrameworkDetails = ({ frameworkData, appDescription = "" }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  const [showFrameworkDetails, setShowFrameworkDetails] = useState(false);
  const [isDeleteDialog, setIsDeleteDialog] = useState(false);
  const [isAddDescription, setIsAddDescription] = useState(false);
  const [isShowDescription, setIsShowDescription] = useState(false);
  // const [frameworkDescription, setFrameworkDescription] = useState(
  //   frameworkData?.frameworkSummary?.description
  // );
  const [frameworkDescription, setFrameworkDescription] =
    useState(appDescription);
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(menuAnchor);

  useEffect(() => {
    if (appDescription && appDescription !== "") {
      setFrameworkDescription(appDescription);
    }
  }, [appDescription]);

  const styles = {
    langchainTitle: {
      display: "flex",
      gap: theme.spacing(1.25),
      alignItems: "center",
    },
    langchainDetails: {
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing(2),
      backgroundColor: theme.palette.surface10.main,
      gap: theme.spacing(3),
      borderRadius: theme.spacing(0.5),
      marginBottom: theme.spacing(1),
    },
    langchainDetailsCard: {
      display: "flex",
      gap: theme.spacing(4),
    },
    detailsSection: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(3),
      flexShrink: 0,
    },
    frameworkDetails: {},
    summaryDetailsRow: {
      display: "grid",
      gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
      gridColumnGap: theme.spacing(6),
      gridRowGap: theme.spacing(2),
    },
    instanceDetailsRow: {
      display: "grid",
      gridTemplateColumns: "repeat(3, minmax(0, 1fr));",
      gridColumnGap: theme.spacing(6),
      gridRowGap: theme.spacing(2),
    },
    instanceDetails: {
      minWidth: "50%",
    },
    instanceDetailsHeader: {
      display: "flex",
      gap: theme.spacing(1),
      alignItems: "center",
    },
    divider: {
      height: "13px",
      margin: "auto",
      borderWidth: "1px",
    },
    miscDetails: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      flexGrow: 1,
      alignItems: "end",
    },
    useTimeStamp: {
      display: "flex",
      gap: theme.spacing(1),
    },
    lastUsedText: {
      whiteSpace: "nowrap",
    },
    showHideDetails: {
      overflow: "hidden",
      height: showFrameworkDetails ? "auto" : "0%",
      display: showFrameworkDetails ? "block" : "none",
      transition: "all 0.5s",
      flexDirection: "column",
      gap: theme.spacing(2),
    },
    frameworkDetailsHeader: {
      display: "flex",
      alignItems: "top",
      width: "fit-content",
    },
    deleteFramework: {
      color: theme.palette.critical.main,
    },
    frameworkInitialDetail: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(0.75),
    },
    frameworkHeader: {
      display: "flex",
      justifyContent: "space-between",
    },
    accordionSummary: {
      width: "100%",
    },
    icon: {
      color: `${theme.palette.primaryBlue.main} !important`,
      fontSize: theme.spacing(2.25),
    },
    summaryContainer: {
      display: "flex",
      gap: theme.spacing(16),
      justifyContent: "space-between",
    },
    actionsContainer: {
      display: "flex",
      gap: theme.spacing(1),
      alignItems: "center",
    },
    summary: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(0.75),
    },
    summaryText: {
      fontSize: "13px",
      fontWeight: 500,
    },
    actions: {
      display: "flex",
      gap: theme.spacing(0.5),
      alignItems: "center",
    },
    menuIcon: {
      fontSize: theme.spacing(2.5),
      color: theme.palette.primaryBlue.main,
    },
    iconButton: {
      height: "fit-content",
      width: "fit-content",
    },
    description: {
      display: "inline",
    },
    descriptionText: {
      marginRight: theme.spacing(0.5),
      wordBreak: "break-word",
    },
  };

  //useEffect(() => {
  //  setFrameworkDescription(localStorage.getItem("appDescription") || "");
  //}, [localStorage.getItem("appDescription")]);

  const cloudType = frameworkData?.providerInfo?.cloudType;
  const getCloudTypeIcon = () => {
    switch (cloudType) {
      case "gcp":
        return GCPIcon;
      case "aws":
        return AWSIcon;
      default:
        return DesktopIcon;
    }
  };

  // Delete App
  // const handleDelete = async () => {
  //   const payload = {
  //     customerId: getCustomerId(),
  //     id: params.id,
  //   };
  //   const resp = await dispatch(deleteFrameworkSlice(payload));
  //   if (resp?.payload?.success) {
  //     Toaster(TOASTER_TYPES.SUCCESS, resp?.payload?.success?.message);
  //   } else {
  //     Toaster(TOASTER_TYPES.ERROR, resp?.payload?.error?.message);
  //   }
  //   history.push("/");
  //   setIsDeleteDialog(false);
  // };

  const getLastUsedDate = (lastUsedDate) => {
    const convertedDate = lastUsedDate ? convertUTCToLocal(lastUsedDate) : "";
    if (convertedDate) {
      return `${getDifferenceInDays(
        new Date(),
        new Date(convertedDate.toDate())
      )} | ${convertedDate.format("hh:mm A")}`;
    }
    return "-";
  };

  const vectorDB = frameworkData?.frameworkSummary?.vectorDB;

  // Add description for App
  // const handleAddDescriptionForFramework = async (val) => {
  //   const payload = {
  //     customerId: getCustomerId(),
  //     id: params.id,
  //     description: val,
  //   };
  //   const resp = await dispatch(addDescriptionForFrameworkSlice(payload));
  //   if (resp?.payload?.success) {
  //     Toaster(
  //       TOASTER_TYPES.SUCCESS,
  //       frameworkDescription
  //         ? "You have successfully updated the description!"
  //         : "You have successfully added the description!"
  //     );
  //     setFrameworkDescription(val);
  //   } else {
  //     Toaster(TOASTER_TYPES.ERROR, resp?.payload?.error?.message);
  //   }
  //   setIsAddDescription(false);
  // };

  const handleAddDescriptionForFramework = (val) => {
    //localStorage.setItem("appDescription", val);
    setFrameworkDescription(appDescription);
    setIsAddDescription(false);
    Toaster(
      TOASTER_TYPES.SUCCESS,
      frameworkDescription
        ? "You have successfully updated the description!"
        : "You have successfully added the description!"
    );
  };

  const handleShowDescription = () => setIsShowDescription((prev) => !prev);

  const appName = frameworkData?.frameworkSummary?.name;
  const version =
    frameworkJSONData.find((app) => app?.name === appName)?.version || "";

  return (
    <React.Fragment>
      <Accordion>
        <Box sx={styles.frameworkHeader}>
          <Box sx={styles.langchainTitle}>
            <img src={LangchainHexagonIcon} alt="langchain" />
            <Box sx={styles.frameworkInitialDetail}>
              <Box sx={styles.langchainTitle}>
                <Typography>{frameworkData?.frameworkSummary?.name}</Typography>
                <img src={getCloudTypeIcon()} alt="aws" width={20} />
              </Box>
              <Typography
                variant="caption"
                color={theme.palette.surface50.main}
              >{`Last Used: ${getDDMMYYYY(moment.now())}`}</Typography>
            </Box>
          </Box>
          <Box sx={styles.actions}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={styles.icon} />}
              onClick={() => setShowFrameworkDetails(!showFrameworkDetails)}
            >
              <TextButton sx={styles.lastUsedText}>
                {`${showFrameworkDetails ? "Hide" : "Show"} Details`}
              </TextButton>
            </AccordionSummary>
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              sx={styles.divider}
            />
            <IconButton
              onClick={(e) => setMenuAnchor(e.currentTarget)}
              sx={styles.iconButton}
            >
              <MoreVertIcon sx={styles.menuIcon} />
            </IconButton>
            <Menu
              open={isMenuOpen}
              anchorEl={menuAnchor}
              onClose={() => setMenuAnchor(null)}
            >
              <MenuItem>
                <TextButton
                  startIcon={<DeleteOutlineOutlined />}
                  color="error"
                  onClick={() => setIsDeleteDialog(true)}
                >
                  Delete
                </TextButton>
              </MenuItem>
            </Menu>
          </Box>
        </Box>
        <AccordionDetails>
          <Box sx={styles.langchainDetails}>
            <Box sx={styles.summaryContainer}>
              <Box sx={styles.summary}>
                <Typography
                  color={theme.palette.surface80.main}
                  sx={styles.summaryText}
                >
                  Description
                </Typography>
                <Box sx={styles.description}>
                  <ViewMoreBlock
                    value={frameworkDescription}
                    length={NUM_OF_DEFAULT_CHARACTERS}
                  />
                </Box>
              </Box>
              <Box sx={{ ...styles.detailsSection, ...styles.miscDetails }}>
                <TextButton
                  startIcon={
                    frameworkDescription ? <EditIcon /> : <PostAddIcon />
                  }
                  onClick={() => setIsAddDescription(true)}
                >
                  {frameworkDescription ? "Update" : "Add"} Description
                </TextButton>
              </Box>
            </Box>
            <Box sx={styles.langchainDetailsCard}>
              <Box
                sx={{ ...styles.detailsSection, ...styles.frameworkDetails }}
              >
                <Typography
                  variant="caption"
                  color={theme.palette.surface60.main}
                >
                  Framework Details
                </Typography>

                <Box sx={styles.summaryDetailsRow}>
                  <KeyValueBlock
                    label="Name"
                    value={frameworkData?.frameworkSummary?.family}
                    valueIcon={
                      <img src={LangchainIcon} alt="langchain" width={20} />
                    }
                  />
                  <KeyValueBlock label="Version" value={version} />
                  <KeyValueBlock
                    label="VectorDB"
                    value={`${frameworkData?.frameworkSummary?.vectorDB}`}
                    valueIcon={
                      <img
                        src={getIcon(frameworkData?.frameworkSummary?.vectorDB)}
                        alt=""
                        width={18}
                      />
                    }
                  />

                  <KeyValueBlock
                    label="VectorDB Location"
                    value={frameworkData?.frameworkSummary?.location}
                  />

                  <KeyValueBlock
                    label="Embedding Model"
                    value={frameworkData?.frameworkSummary?.embeddingModel}
                  />
                  <KeyValueBlock
                    label="LLM Model"
                    value={`${
                      frameworkData?.frameworkSummary?.models?.vendor || "-"
                    }${
                      frameworkData?.frameworkSummary?.models?.name &&
                      ` - ${frameworkData?.frameworkSummary?.models?.name}`
                    }`}
                    valueIcon={
                      <img
                        src={
                          frameworkData?.frameworkSummary?.models?.vendor ===
                          "Huggingface"
                            ? HuggingFaceIcon
                            : GptIcon
                        }
                        alt="gpt"
                        width={20}
                      />
                    }
                  />
                </Box>
              </Box>
              <Divider orientation="vertical" flexItem />
              <Box sx={{ ...styles.detailsSection, ...styles.instanceDetails }}>
                <Box
                  sx={{
                    ...styles.frameworkDetailsHeader,
                    ...styles.instanceDetailsHeader,
                  }}
                >
                  <Typography
                    variant="caption"
                    color={theme.palette.surface60.main}
                  >
                    Instance Details
                  </Typography>
                  {/*<Divider orientation="vertical" sx={styles.divider} />*/}
                  {/*<img src={getCloudTypeIcon()} alt="aws" width={20} />*/}
                  <Divider orientation="vertical" sx={styles.divider} />
                  <Typography
                    variant="caption"
                    color={theme.palette.surface40.main}
                  >
                    {frameworkData?.providerInfo?.region}
                  </Typography>
                </Box>
                <Box sx={styles.instanceDetailsRow}>
                  <KeyValueBlock
                    label="Account ID"
                    value={
                      cloudType === "aws"
                        ? frameworkData?.providerInfo?.aws?.accountId
                        : frameworkData?.providerInfo?.gcp?.accountId
                    }
                  />
                  <KeyValueBlock
                    label="Instance ID"
                    value={
                      cloudType === "aws"
                        ? frameworkData?.providerInfo?.aws?.instanceId
                        : frameworkData?.providerInfo?.aws?.instanceId
                    }
                  />

                  <KeyValueBlock
                    label="Created At"
                    value={
                      frameworkData?.createdAt
                        ? getDateTime(
                            convertUTCToLocal(frameworkData?.createdAt)
                          )
                        : "-"
                    }
                  />
                  <KeyValueBlock
                    label="Runtime"
                    value={frameworkData?.instanceDetails?.runtime}
                  />
                  <KeyValueBlock
                    label="Host"
                    value={frameworkData?.instanceDetails?.host}
                  />
                  <KeyValueBlock
                    label="Path"
                    value={frameworkData?.instanceDetails?.path}
                  />
                  <KeyValueBlock
                    label="IP"
                    value={frameworkData?.instanceDetails?.ip}
                  />
                  <KeyValueBlock
                    label="Language"
                    value={frameworkData?.instanceDetails?.language}
                  />
                  <KeyValueBlock
                    label="Language Version"
                    value={frameworkData?.instanceDetails?.languageVersion}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
      {/* <DeleteApplicationDialog
        appName={frameworkData?.frameworkSummary?.name}
        isOpen={isDeleteDialog}
        handleClose={() => setIsDeleteDialog(false)}
        onDelete={handleDelete}
      />*/}
      <AddDescriptionDialog
        isOpen={isAddDescription}
        onClose={() => setIsAddDescription(false)}
        handleSubmit={handleAddDescriptionForFramework}
        description={frameworkDescription || null}
      />
    </React.Fragment>
  );
};

export default LLMFrameworkDetails;
