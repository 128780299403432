export const applicationsList = [
  {
    id: "a3c3b82f-d449-42cc-a096-3fe053292b89",
    name: "ProductInsights",
    version: "0.1.10",
    framework: "langchain",
  },
  {
    id: "ac6a5d3c-5555-5555-a3cd-e64818d279d2",
    name: "AcmeMortgageApp-1",
    version: "0.1.10",
    framework: "langchain",
  },
  {
    id: "ac6a5d3c-7777-7777-a3cd-e64818d279d2",
    name: "Proxima - SupportApp",
    version: "0.1.7",
    framework: "langchain",
  },
  {
    id: "ac6a5d3c-6666-6666-a3cd-e64818d279d2",
    name: "HealthCareApp-1",
    version: "0.1.4",
    framework: "langchain",
  },
];
